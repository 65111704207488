export function loadImages() {
  const containers = document.querySelectorAll('[data-image-loading-container]');
  if (!containers.length) return;

  containers.forEach(container => {
    const images = container.querySelectorAll('img');

    let totalImages = images.length;
    let loadedCount = 0;

    // Helper function to mark an image as loaded
    function markImageLoaded(img) {
      const imageParent = img.parentNode.parentNode;
      if (!imageParent.classList.contains('loaded')) {
        imageParent.classList.add('loaded');
        imageParent.classList.remove('opacity-0');
      }
    }

    images.forEach((img) => {
      // If the image is already complete (cached or instantly available)
      if (img.complete) {
        loadedCount++;
        markImageLoaded(img);
      } else {
        // Not yet loaded, wait for it
        img.addEventListener('load', () => {
          loadedCount++;
          markImageLoaded(img);

          // Check if all images are loaded
          if (loadedCount === totalImages) {
            // All images have loaded
            //console.log('All images in this container have loaded!'); // Uncomment for debugging
          }
        }, { once: true });

        // Handle error scenario if desired
        img.addEventListener('error', () => {
          loadedCount++;
          // Could handle broken image scenario here
          // For now, just treat it as loaded to avoid blocking any "all loaded" logic
          if (loadedCount === totalImages) {
            // All images have attempted to load
            //console.log('All images in this container have attempted to load!'); // Uncomment for debugging
          }
        }, { once: true });
      }
    });

    // If all images were already loaded (e.g., from cache):
    if (loadedCount === totalImages && totalImages > 0) {
      //console.log('All images in this container were already loaded!'); // Uncomment for debugging
    }
  });
}